import bun1Img from "./assets/bun-dog-1.jpeg";
import bun2Img from "./assets/bunny-bun.jpg";
import bun3Img from "./assets/cat-bun.jpg";
import bun4Img from "./assets/bun-mocha.jpg";
import bun5Img from "./assets/bun-ethboi.jpg";
import bun6Img from "./assets/bun-mojo.jpg";

export const CORE_CONCEPTS = [
  {
    image: bun1Img,
    title: "Dog In $BUN",
    description:
      "The original and our opinion best thing in $BUN. Dog in Bun stands out for its adorable concept and the cheerful camaraderie it inspires among its enthusiasts.",
  },
  {
    image: bun2Img,
    title: "Bunny in $BUN",
    description:
      "This $BUNny has got it all!  A fluffy bunny nestled snugly inside a soft bun, a gentle reminder of the fun and simple pleasures life can offer.",
  },
  {
    image: bun3Img,
    title: "Cat in $BUN",
    description:
      "An irresistibly cute mischievous cat's face peeking out from between the halves of a soft burger bun. Bunderful!",
  },
];

export const BUN_TEAM = [
  {
    image: bun4Img,
    title: "Mochabyte",
    description:
      "Mochabyte is a crypto enthusiast with a keen interest in blockchain technology and digital currencies. Their passion for cryptocurrency is matched by their love for buns, showcasing a unique blend of interests that spans from tech to culinary delights.",
    url: "https://twitter.com/Mocha_byte",
  },
  {
    image: bun5Img,
    title: "Ethboi",
    description:
      "Ethboi is a cryptocurrency developer with a flair for $BUN-based innovations, a unique culinary experiment with burger buns. Known for blending diverse technologies in his blockchain projects, he mirrors this creativity in the world of $BUN.",
    url: "https://twitter.com/EthBoi_",
  },
  {
    image: bun6Img,
    title: "Master Mojo",
    description:
      "Master Mojo is a bun enthusiast and crypto specialist with a passion for naturism. He combines his love for $BUN with deep knowledge in cryptocurrency, offering unique insights into the digital economy. ",
    url: "https://twitter.com/mastermojo83",
  },
];

export const EXAMPLES = {
  discord: {
    title: "AmaZing Discord",
    description: "Join us and become an Underdog",
    code: ``,
  },
  twitter: {
    title: "",
    description: "Stay updated with our developing team and ZING Coin",
    code: ``,
  },
};
